/* Importing Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Global Styles */
a {
    text-decoration: none !important;
}

/* Custom Font */
.inter-font {
    font-family: 'Inter', sans-serif !important;
}

/* Header Styles */
.header {
    margin-top: 20px !important;
}

.header-mac {
    font-size: 20px !important;
    font-weight: bold !important;
}

/* Link Styles */
.custom-link {
    font-size: 20px !important;
    font-weight: 300 !important;
}

.icon-link {
    color: inherit;
    text-decoration: none;
}

/* Image Styles */
.profile-image {
    transform: scale(1); /* Default size */
}

.image-container {
    width: 240px !important;
    height: 240px !important;
    padding: 0 !important;
}

/* Dropdown Styles */
.custom-dropdown {
    background-color: transparent !important;
    border: none !important;
}

#dropdown-basic.custom-dropdown::after {
    display: none;
}

/* Text Styles */
.text-content {
    font-family: sans-serif;
    font-size: 15px;
    line-height: 1.2;
}

.text-row {
    margin-top: 17px !important;
}

.custom-paragraph {
    margin-bottom: 5px;
}

.name-item {
    font-weight: bolder !important;
    font-size: 15px !important;
}

/* Animation Styles */
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

.fade-in {
    opacity: 0;
    animation: fade-in 2s forwards;
}

.fade-in.visible {
    animation-play-state: running;
}

.hide-cursor::after {
    display: none;
}

/* Media Queries */
@media (max-width: 767px) {
    .profile-image {
        transform: scale(0.5);
    }

    .image-container {
        height: 120px !important;
    }

    .header {
        margin-top: 10px !important;
    }

    .text-row {
        margin-top: 100px !important;
    }

    .picture-row {
        margin-top: -15px !important;
    }
}

@media (min-width: 768px) {
    .text-content {
        font-size: 17px;
    }

    .name-row-mac {
        margin-bottom: -15px !important;
        margin-top: 15px !important;
    }

    .text-row {
        margin-top: 20px !important;
    }
}