/* Existing styles */

/* Blog Post Styles */

.blog-post {
    margin-top: 3rem; /* Increase the top margin */
    /* ... other styles ... */
  }
  
  .blog-post-title {
    font-size: 1.5rem;
    color: #333; /* Adjust based on your theme */
    margin-bottom: 1rem;
  }
  
  .blog-post-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #666; /* Adjust based on your theme */
    margin-bottom: 1rem;
  }
  
  /* Continue with existing styles */